<template>
  <v-card flat class="service_card_container service_buttons_container">
    <v-row justify="center" v-if="showButtons">
      <button-card
        class="service_button_card"
        @click="switchEditMode"
        v-if="isBrandOrCreator"
      >
        <div class="button_card_items">
          <ellipse-icon class="button_card_icon"><edit-inquiry /></ellipse-icon>
          <span>Edit Collection</span>
        </div>
      </button-card>

      <button-card
        class="service_button_card"
        variant="danger-theme"
        id="deleteInquiryButton"
        @click="deleteCollection(collectionData.id)"
        v-if="isBrandOrCreator"
      >
        <div class="button_card_items">
          <ellipse-icon class="button_card_icon" variant="danger" v-slot="props"
            ><delete-icon :fill="props.fill"
          /></ellipse-icon>
          <span>Delete Collection</span>
        </div>
      </button-card>
    </v-row>
    <v-row justify="center" v-else>
      <v-col cols="12" lg="9" v-if="editMode">
        <create-collection
          :collectionName="collectionName"
          @update-event="offSwitchEditMode"
        ></create-collection>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import restAdapter from "../../../restAdapter";
import notification from "../../../notification";
const CreateCollection = () => import("../CreateCollection.vue");

export default {
  name: "OptionsTab",
  components: {
    CreateCollection,
  },
  props: {
    collectionData: {
      type: Object,
      required: true,
    },
    isBrandOrCreator: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showButtons: true,
      editMode: false,
      collectionName: "",
    };
  },
  methods: {
    switchEditMode() {
      this.showButtons = false;
      this.editMode = true;
      this.collectionName = this.collectionData.name;
    },
    offSwitchEditMode(name) {
      this.showButtons = true;
      this.editMode = false;
      this.$emit("edit", name);
    },
    deleteCollection(id) {
      const answer = prompt(
        "Delete this Collection ? Type 'delete' and choose OK to delete"
      );
      if (answer === "delete") {
        restAdapter.delete("/api/collection/" + id).then(() => {
          if (this.isBrandOrCreator) {
            notification.success("Collection deleted successfully");
          } else if (this.getIsSupplier()) {
            notification.success(
              "Collection delete request sent to account manager"
            );
          }
          this.$router.push({ name: "All Collections" });
        });
      } else if (answer !== null) {
        notification.error(
          "Please type the exact word 'delete'. Style was not deleted."
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.service_card_container {
  margin: 0px;
  margin-top: 25px;
  min-height: 400px;
  padding: 30px 0;
}
.service_buttons_container {
  padding-top: 50px;
}
.service_button_card {
  margin: 20px;
}
.button_card_items {
  display: flex;
  align-items: center;
}
.button_card_icon {
  float: left;
  margin: 0 15px;
}
</style>
